<template>
    <transition name="fade-transition">
        <v-container fluid fill-height id="curriculumPage" v-bind:style="{ 'background-image': 'url(' + require(`@/assets/classroom-background.jpg`) + ')' }">
                <v-container v-if="!fetching">
                    <v-row no-gutters class="text-center medium-text heading">CURRICULUM</v-row>
                    <v-row no-gutters>
                        <v-col cols="12" sm="11" class="curriculum">
                            <v-row>
                                <v-col cols="12" sm="12">
                                <template v-for="(item, index) in Rooms">
                                    <!-- eslint-disable-next-line -->
                                    <div class="medium-text">
                                        <a :key="item.id" @click="goToRoom(item.id, item.title)">UNIT {{index+1}}: {{item.title}}</a>
                                    </div>
                                </template>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="1">
                            <v-img class="twoB" :src="require(`@/assets/2B-with-Beaker.png`)"></v-img>
                            <a @click="$router.go(-1)" class="back-btn"><v-img :src="require(`@/assets/back-arrow.png`)"></v-img></a>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container fluid class="loading" v-if="fetching">
                    <v-progress-circular
                        indeterminate
                        color="#000"
                        class="progressLoader"
                    ></v-progress-circular>
                </v-container>
        </v-container>
    </transition>
</template>
<script>
export default {
    name: 'Curriculum', 
    data(){
        return{

        }
    },
    mounted(){
        this.getRooms();
    },
    methods:{
        getRooms(){
            var _this = this;
            if(this.User != null){
                if(this.User.role == 'teacher'){
                    this.fetching = true;
                    setTimeout(function(){
                        _this.$store.dispatch('getRooms');
                    }, 3000);
                }
                else{
                    this.$router.push({path:'/sign-in'});
                }
            }
        },
        goToRoom(Room_id, Room_title){
            this.$router.push({ name: 'Lessons', params: { id: Room_id, name: Room_title, slug: Room_title.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-') } })
        }
    },
    computed:{
        User(){
            return this.$store.state.user;
        },
        fetching:{
            get(){
                return this.$store.state.fetching;
            },
            set(value){
                this.$store.state.fetching = value;
            }
        },
        Rooms(){
            return this.$store.state.Rooms;
        }
    }
}
</script>
<style scoped>
.medium-text a, .medium-text.heading, .medium-text::marker{
    font-family: 'Lomo', sans-serif;
    color: #fff;
    text-shadow: 5px 5px 5px rgba(0,0,0,0.5);
    -webkit-text-stroke: 2px black;
}
.medium-text a:hover{
    color: #00B917;
}
.medium-text.heading{
    margin-bottom: 20px;
    display: block;
}
.twoB{
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 100px;
}
.back-btn{
    max-width: 85px;
    position: fixed;
    left: 30px;
    bottom: 30px;
    z-index: 12;
}
.loading{
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255,2555,255,0.9);
}
.loading .v-progress-circular{
    height: calc(15vh) !important;
    width: calc(13vw) !important;
    margin: auto;
}
.curriculum{
    z-index: 10;
}
@media screen and (max-width: 767px) {
    .medium-text a, .medium-text.heading, .medium-text::marker{
        text-shadow: none !important;
    }
    .curriculum{
        margin-top: 35px;
    }
    .medium-text.heading{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: rgba(0,0,0,0.75);
        z-index: 11;
        padding: 10px 0px;
    }
    
}
@media screen and (max-width: 1000px) {
    .back-btn{
        top: 5px;
        left: 5px;
        z-index: 12;
        bottom: auto;
        max-width: 50px;
    }
}
</style>